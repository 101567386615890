// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'FeaturedNews',
	mixins: [MixinResponsive],

	data: function() {
		return {
			news: [],
			responsive: {
				classNewsCards: false,
				showMobileVersion: false
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Home/FeaturedNews.json')
		const data = await request.json()
		this.news = data
	},

	computed: {
		_getNewsClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.classNewsCards }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.classNewsCards = breakpoint < Breakpoints.XXLarge
			responsive.showMobileVersion = breakpoint < Breakpoints.Large
		},

		onNewClick: function(index) {
			const { sourceUrl } = this.news[index]
			if (sourceUrl) window.open(sourceUrl, '_blank')
		}
	}
}