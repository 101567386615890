// Components
import CustomCarousel from '@/components/common/CustomCarousel/template.vue'
import Footer from '@/components/common/Footer/template.vue'
import Header from '@/components/common/Header/template.vue'
import BottomBar from '@/components/views/BottomBar/template.vue'
import ContactForm from '@/components/views/ContactForm/template.vue'
import FeaturedNews from '@/components/views/FeaturedNews/template.vue'
import FeaturedSections from '@/components/views/FeaturedSections/template.vue'
import LatestBulletin from '@/components/views/LatestBulletin/template.vue'
import SocialMedia from '@/components/views/SocialMedia/template.vue'
import SponsorsSlider from '@/components/views/SponsorsSlider/template.vue'

export default {
	name: 'Home',

	components: {
		BottomBar,
		CustomCarousel,
		ContactForm,
		FeaturedNews,
		FeaturedSections,
		Footer,
		Header,
		LatestBulletin,
		SocialMedia,
		SponsorsSlider
	}
}