// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'LaborDataForm',
	mixins: [MixinResponsive],

	data: function() {
		return {
			form: {
				preStudyCenter: '',
				preStudyCenterCareer: '',
				preStudyCenterCountry: '',
				postStudies: [],
				postStudyCenter: '',
				postStudyCenterCountry: '',
				position: '',
				workplace: '',
				workplaceCountry: '',
				workplaceModality: ''
			},
			responsive: {
				showMobileVersion: false
			}
		}
	},

	computed: {
		_getResponsiveClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XLarge
		},

		clear: function() {
			this.form = {
				preStudyCenter: '',
				preStudyCenterCareer: '',
				preStudyCenterCountry: '',
				postStudies: [],
				postStudyCenter: '',
				postStudyCenterCountry: '',
				position: '',
				workplace: '',
				workplaceCountry: '',
				workplaceModality: ''
			}
		}
	}
}