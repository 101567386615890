// Components
import Footer from '@/components/common/Footer/template.vue'
import Header from '@/components/common/Header/template.vue'
import ContactForm from '@/components/views/ContactForm/template.vue'
import ContentCard from '@/components/views/ContentCard/template.vue'
import ItemsOnGrid from '@/components/views/ItemsOnGrid/template.vue'
import SocialMedia from '@/components/views/SocialMedia/template.vue'
import WelcomeCard from '@/components/views/WelcomeCard/template.vue'

// Component Export
export default {
	name: 'CourseDetails',

	components: {
		ContactForm,
		ContentCard,
		Footer,
		Header,
		ItemsOnGrid,
		SocialMedia,
		WelcomeCard
	},

	data: function() {
		return {
			course: undefined
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/CourseDetails/Courses.json')
		const data = await request.json()
		this.course = data[this._getCourseId()]
	},

	mounted: function() {
		this._initComponents()
	},

	methods: {
		_getCourseId: function() {
			return this.$route.query.id
		},

		_getCourseProperty: function(prop) {
			return this.course?.[prop] || ''
		},

		_initComponents: function() {
			if (this.course) {
				const { contentCard1, contentCard2, contentCard3, itemsOnGrid, welcomeCard } = this.$refs
				const { details } = this.course

				// WelcomeCard
				const welcomeCardData = details.INTRODUCCION
				welcomeCard.states = { data: welcomeCardData }

				// ContentCard (1)
				const contentCardData1 = details.INFORMACION_CURSO
				contentCard1.states = { lists: contentCardData1.lists || [], paragraph: contentCardData1.paragraph }

				// ContentCard (2)
				const contentCardData2 = details.CURSO_DIRIGIDO_A
				contentCard2.states = { lists: contentCardData2.lists || [], paragraph: contentCardData2.paragraph }

				// ContentCard (3)
				const contentCardData3 = details.FORMATO_CLASES
				contentCard3.states = { lists: contentCardData3.lists || [], paragraph: contentCardData3.paragraph }

				// ItemsOnGrid
				const itemsOnGridData = details.INFORMACION_GENERAL
				itemsOnGrid.states = { items: itemsOnGridData || [] }
			}
		},

		onHButtonClick: function() {
			const routeData = this.$router.resolve({ name: 'CourseEntryForm' })
			window.open(`${ routeData.href }?id=${ this._getCourseId() }`, '_blank')
		}
	},

	watch: {
		course: function() {
			this._initComponents()
		}
	}
}