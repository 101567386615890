// Components
import SponsorsSlider from '@/components/views/SponsorsSlider/template.vue'

// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'WelcomeCard',
	mixins: [MixinResponsive],

	components: {
		SponsorsSlider
	},

	data: function() {
		return {
			responsive: {
				showMobileVersion: false
			},
			states: {
				data: []
			}
		}
	},

	computed: {
		_getResponsiveBreakPoints: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XXLarge
		},
	}
}