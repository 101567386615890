// Dependencies
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Views
import CourseDetails from '@/views/CourseDetails/template.vue'
import CourseEntryForm from '@/views/CourseEntryForm/template.vue'
import CoursesAndDiplomas from '@/views/CoursesAndDiplomas/template.vue'
import Home from '@/views/Home/template.vue'

// Router Export
export default new VueRouter({
	base: process.env.BASE_URL,
	mode: 'hash',
	routes: [
		{ path: '/',                             name: 'Home',               component: Home },
		{ path: '/diplomados/curso/informacion', name: 'CourseDetails',      component: CourseDetails },
		{ path: '/diplomados/curso/inscripcion', name: 'CourseEntryForm',    component: CourseEntryForm },
		{ path: '/diplomados/cursos',            name: 'CoursesAndDiplomas', component: CoursesAndDiplomas },
	]
})