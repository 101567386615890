// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'ComplementaryRecords',
	mixins: [MixinResponsive],

	data: function() {
		return {
			options: [
				{
					subtitle: 'Medio por el cual obtuvo información del Programa',
					items: [
						{ type: 'checkbox', id: 'cb01', checked: false, label: 'Busqueda por internet' },
						{ type: 'checkbox', id: 'cb02', checked: false, label: 'Facebook' },
						{ type: 'checkbox', id: 'cb03', checked: false, label: 'Instagram' },
						{ type: 'checkbox', id: 'cb04', checked: false, label: 'Email marketing' },
						{ type: 'checkbox', id: 'cb05', checked: false, label: 'Recomendación' },
						{ type: 'checkbox', id: 'cb06', checked: false, label: 'Mi lugar de trabajo' },
						{ type: 'checkbox', id: 'cb07', checked: false, label: 'Congreso y seminarios' },
						{ type: 'checkbox', id: 'cb08', checked: false, label: 'Radio' },
						{ type: 'checkbox', id: 'cb09', checked: false, label: 'Otro' }
					]
				},
				{
					subtitle: 'Por qué medio le gustaría ser contactado (a)',
					items: [
						{ type: 'checkbox', id: 'cb10', checked: false, label: 'Correo Electrónico' },
						{ type: 'checkbox', id: 'cb11', checked: false, label: 'Telegram' },
						{ type: 'checkbox', id: 'cb12', checked: false, label: 'WhatsApp' },
						{ type: 'checkbox', id: 'cb13', checked: false, label: 'Número de contacto' },
						{ type: 'checkbox', id: 'cb14', checked: false, label: 'Otro' }
					]
				}
			],
			responsive: {
				showMobileVersion: false
			},
			states: {
				subscribe: true
			}
		}
	},

	computed: {
		_getResponsiveClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_getSelectedClass: function(item) {
			return item.checked ? 'checked' : null
		},

		_getSelectedRadioClass: function(checked) {
			return this.states.subscribe === checked ? 'checked' : null
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XXLarge
		},

		clear: function() {
			for (const option of this.options) {
				for (const item of option.items) {
					item.checked = false
				}
			}
			this.states.subscribe = true
		},

		getGroupOptions: function(id) {
			const options = []
			for (const item of this.options[id].items) {
				if (item.checked) {
					options.push(item.label)
				}
			}
			return options
		},

		onCRItemClick: function(item) {
			item.checked = !item.checked
		},

		onCRRadioButtonClick: function(checked) {
			this.states.subscribe = checked
		}
	}
}