// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'SocialMedia',
	mixins: [MixinResponsive],

	data: function() {
		return {
			media: [
				{ color: '#5851DB', icon: 'facebook-f', link: 'https://www.facebook.com/idegys' },
				{ color: '#518FDB', icon: 'twitter', link: 'https://twitter.com/idegyscl' },
				{ color: '#6ECADC', icon: 'linkedin', link: 'https://www.linkedin.com/company/idegys' },
				{ color: '#EA4C89', icon: 'instagram', link: 'https://www.instagram.com/idegys/?hl=es-la' },
			],
			responsive: {
				showMobileVersion: false
			},
			states: {
				description: ''
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Shared/SocialMedia.json')
		const data = await request.json()
		this.states = data
	},

	methods: {
		_getTitle: function() {
			return this.responsive.showMobileVersion ? 'Siguenos en\nnuestras' : 'Siguenos en nuestras\nRedes Sociales'
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.Large
		}
	}
}