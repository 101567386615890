export default {
	name: 'Logo',

	props: {
		scale: { type: Number, default: 1 },
		showIcon: { type: Boolean, default: true },
		showText: { type: Boolean, default: true }
	},

	methods: {
		_getIconHeight: function() {
			return this.$props.scale * 95.333
		},

		_getIconWidth: function() {
			return this.$props.scale * 101.358
		},

		_getTextHeight: function() {
			return this.$props.scale * 54.593
		},

		_getTextWidth: function() {
			return this.$props.scale * 178.877
		},

		onLogoClick: function() {
			if (this.$route.name !== 'Home') {
				this.$router.push({ name: 'Home' })
			}
		}
	}
}