// Dependencies
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
Vue.component('fa', FontAwesomeIcon)

// Brands Icons
import {
	/* F */ faFacebookF,
	/* G */ faGoogle,
	/* I */ faInstagram,
	/* L */ faLinkedin,
	/* T */ faTwitter, faTwitch,
	/* Y */ faYoutube
} from '@fortawesome/free-brands-svg-icons'

// Regular Icons
import {
	/* C */ faCalendarAlt, faClock,
} from '@fortawesome/free-regular-svg-icons'

// Solid Icons
import {
	/* A */ faAlignJustify,
	/* C */ faChevronLeft, faChevronRight,
	/* E */ faEdit,
	/* F */ faFileInvoice, faFileSignature,
	/* H */ faHandHoldingUsd,
	/* M */ faMapMarkerAlt,
	/* U */ faUsers
} from '@fortawesome/free-solid-svg-icons'

library.add(
	/* A */ faAlignJustify,
	/* C */ faCalendarAlt, faChevronLeft, faChevronRight, faClock,
	/* E */ faEdit,
	/* F */ faFacebookF, faFileInvoice, faFileSignature,
	/* G */ faGoogle,
	/* H */ faHandHoldingUsd,
	/* I */ faInstagram,
	/* L */ faLinkedin,
	/* M */ faMapMarkerAlt,
	/* T */ faTwitter, faTwitch,
	/* U */ faUsers,
	/* Y */ faYoutube
)