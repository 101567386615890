// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'ContactCard',
	mixins: [MixinResponsive],

	data: function() {
		return {
			responsive: {
				showMobileVersion: false
			},
			states: {
				email: '',
				phone: ''
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/CourseDetails/Courses.json')
		const data = await request.json()
		const contact = data[this.$route.query.id].details.CONTACTO
		this.states = contact
	},

	computed: {
		_getResponsiveClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XLarge
		}
	}
}