// Constants
import { Breakpoints } from '@/constants/Breakpoints'

export default {
	data: function() {
		return {
			currentResponsiveBreakpoint: undefined,
			breakpoints: [
				Breakpoints.XSmall,
				Breakpoints.Small,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge,
				Breakpoints.XXLarge,
			]
		}
	},

	created: function() {
		this._resizeEventHandler()
	},

	beforeMount: function() {
		window.addEventListener('resize', this._resizeEventHandler)
	},

	beforeDestroy: function() {
		window.removeEventListener('resize', this._resizeEventHandler)
	},

	methods: {
		_resizeEventHandler: function() {
			const width = window.innerWidth
			const found = this.breakpoints.find((x) => width <= x) || Breakpoints.Widescreen
			this.currentResponsiveBreakpoint = found
		},

		getBreakpointPrefix: function() {
			switch (this.currentResponsiveBreakpoint) {
				case Breakpoints.XSmall: return 'xs'
				case Breakpoints.Small: return 'sm'
				case Breakpoints.Medium: return 'md'
				case Breakpoints.Large: return 'lg'
				case Breakpoints.XLarge: return 'xl'
				case Breakpoints.XXLarge: return 'xxl'
				case Breakpoints.Widescreen: return 'ws'
			}
		}
	},

	watch: {
		currentResponsiveBreakpoint: function(newVal) {
			if ('_onResponsiveBreakpoint' in this) this._onResponsiveBreakpoint(newVal)
		}
	}
}