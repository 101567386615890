import { render, staticRenderFns } from "./template.vue?vue&type=template&id=08a48379&scoped=true&"
import script from "@/components/views/SponsorsSlider/component.js?vue&type=script&lang=js&"
export * from "@/components/views/SponsorsSlider/component.js?vue&type=script&lang=js&"
import style0 from "@/components/views/SponsorsSlider/style.scss?vue&type=style&index=0&id=08a48379&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a48379",
  null
  
)

export default component.exports