// Component Export
export default {
	name: 'CustomCarousel',

	data: function() {
		return {
			states: {
				items: [],
				isSliding: null,
				slideNum: 0
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Home/CustomCarousel.json')
		const data = await request.json()
		this.states.items = data || []
	},

	methods: {
		onCCImageClick: function(item) {
			if (item.sourceType === 'external') {
				window.open(item.sourceUrl, '_blank')
			}
			else {
				const routeData = this.$router.resolve({ name: item.sourceUrl })
				window.open(routeData.href, '_blank')
			}
		},

		onCCSlideEnd: function(slide) {
			this.states.isSliding = false
		},

		onCCSlideStart: function(slide) {
			this.states.isSliding = true
		}
	}
}