// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'BottomBar',
	mixins: [MixinResponsive],

	data: function() {
		return {
			responsive: {
				classSmallBreakpoint: false
			}
		}
	},

	methods: {
		_getAuthorText: function() {
			return this.responsive.classSmallBreakpoint ? 'Pagina diseñada por Buenamano' : 'Copyright © 2021'
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.classSmallBreakpoint = breakpoint < Breakpoints.Medium
		}
	}
}