// Componennts
import SecondLogo from '@/components/common/SecondLogo/template.vue'

// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'Footer',
	mixins: [MixinResponsive],

	components: {
		SecondLogo
	},

	data: function() {
		return {
			responsive: {
				classSmallBreakpoint: false,
				showMobileVersion: false
			},
			socialMedias: [
				{ icon: ['fab', 'facebook-f'], to: 'https://www.facebook.com/idegys' },
				{ icon: ['fab', 'twitter'], to: 'https://twitter.com/idegyscl' },
				{ icon: ['fab', 'linkedin'], to: 'https://www.linkedin.com/company/idegys' },
				{ icon: ['fab', 'instagram'], to: 'https://www.instagram.com/idegys/?hl=es-la' }
			],
			states: {
				categories: [],
				information: {}
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Shared/Footer.json')
		const data = await request.json()
		this.states = data
	},

	computed: {
		_getBreakpoinsClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.classSmallBreakpoint = breakpoint < Breakpoints.Medium
			responsive.showMobileVersion = breakpoint < Breakpoints.XLarge
		},

		onLinkCategoryClick: function(item) {
			const { external, internal } = item
			if (external) {
				window.open(external, '_blank')
			}
			else if (internal) {
				const routeData = this.$router.resolve({ name: internal })
				window.open(routeData.href, '_blank')
			}
		}
	}
}