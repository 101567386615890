// Components
import SecondLogo from '@/components/common/SecondLogo/template.vue'

// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'FeaturedSections',
	mixins: [MixinResponsive],

	components: {
		SecondLogo
	},

	data: function() {
		return {
			responsive: {
				showMobileVersion: false
			},
			states: {
				section1: {},
				section2: {
					sub1: {},
					sub2: {},
					sub3: {}
				},
				section3: {},
				section4: {}
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Home/FeaturedSections.json')
		const data = await request.json()

		// Almacenar la Información correspondiente a cada Sección.
		this.states.section1 = data.section1
		this.states.section2 = data.section2
		this.states.section3 = data.section3
		this.states.section4 = data.section4
	},

	computed: {
		_getResponsiveBreakPoints: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_getSectionColor: function(index) {
			const { color } = this.states[`section${index}`]
			const _color = color || '#555'
			return `background-color: ${_color};`
		},

		_getSubSectionColor: function(index) {
			const { color } = this.states.section2[`sub${index}`]
			const _color = color || '#555'
			return `background-color: ${_color};`
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.Large
		},

		onSectionClick: function(index) {
			const { sourceUrl, sourceType } = this.states[`section${index}`]
			if (sourceUrl && sourceType) {
				switch (sourceType) {
					case 'external': return window.open(sourceUrl, '_blank')
					case 'internal':
						const routeData = this.$router.resolve({ name: sourceUrl })
						window.open(routeData.href, '_blank')
						break
				}
			}
		},

		onSubSectionClick: function(index) {
			const { sourceUrl, sourceType } = this.states.section2[`sub${index}`]
			if (sourceUrl && sourceType) {
				switch (sourceType) {
					case 'external': return window.open(sourceUrl, '_blank')
					case 'internal':
						const routeData = this.$router.resolve({ name: sourceUrl })
						window.open(routeData.href, '_blank')
						break
				}
			}
		}
	}
}