// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'ContactForm',
	mixins: [MixinResponsive],

	data: function() {
		return {
			responsive: {
				classMobileVersion: false,
				showFiguresVector: true
			},
			states: {
				email: [],
				phone: '',
				address: []
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Shared/ContactForm.json')
		const data = await request.json()
		this.states = data
	},

	computed: {
		_getResponsiveBreakpointClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.classMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.classMobileVersion = breakpoint < Breakpoints.XXLarge
			responsive.showFiguresVector = breakpoint > Breakpoints.Medium
		}
	}
}