import { render, staticRenderFns } from "./template.vue?vue&type=template&id=9a75a60c&scoped=true&"
import script from "@/components/views/LatestBulletin/component.js?vue&type=script&lang=js&"
export * from "@/components/views/LatestBulletin/component.js?vue&type=script&lang=js&"
import style0 from "@/components/views/LatestBulletin/style.scss?vue&type=style&index=0&id=9a75a60c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a75a60c",
  null
  
)

export default component.exports