export default {
	name: 'SecondLogo',

	props: {
		scale: { type: Number, default: 1 },
		showIcon: { type: Boolean, default: true },
		showText: { type: Boolean, default: true }
	},

	methods: {
		_getIconHeight: function() {
			return this.$props.scale * 211.061
		},

		_getIconWidth: function() {
			return this.$props.scale * 207.608
		}
	}
}