// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'SponsorsSlider',
	mixins: [MixinResponsive],

	data: function() {
		return {
			currentIndex: 0,
			maxShown: 4,
			sponsors: [],
			responsive: {
				classArrows: false,
				classSponsorCard: false,
				classMobileVersion: false,
			},
		}
	},

	created: async function() {
		// Si en la Ruta existe el parametro 'id', obtendrá las imagenes de otra ubicación.
		const courseId = this.$route.query.id

		// Patrocinadores de un Curso en Especifico.
		if (courseId && courseId !== '') {
			const request = await fetch('/resources/json/CourseDetails/Courses.json')
			const data = await request.json()
			this.sponsors = data[courseId].details.PATROCINADORES
		}

		// De lo contrario, fallback al que esta en la carpeta 'Shared'.
		else {
			const request = await fetch('/resources/json/Shared/SponsorsSlider.json')
			const data = await request.json()
			this.sponsors = data
		}
	},

	computed: {
		_generateIndexes: function() {
			const indexes = []
			let index = this.currentIndex
			while (indexes.length < this.maxShown) indexes.push(index++)
			return indexes
		},

		_getArrowsBreakpointClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.classArrows }
		},

		_getSponsorBreakpointClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.classSponsorCard }
		}
	},

	methods: {
		_getSponsorImage: function(index) {
			const sponsor = Math.abs(index) % this.sponsors.length
			const _sponsor = this.sponsors[sponsor]
			return _sponsor ? _sponsor.photoUrl : ''
		},

		_onArrowClicked: function(step) {
			this.currentIndex += step
		},
		
		_onResponsiveBreakpoint: function(breakpoint) {
			this.responsive.classArrows = breakpoint < Breakpoints.Large
			this.responsive.classSponsorCard = breakpoint < Breakpoints.Widescreen
			this.responsive.classMobileVersion = breakpoint < Breakpoints.Large

			// Reducir el numero de elementos.
			breakpoint < Breakpoints.Large
			? this.maxShown = 1
			: breakpoint < Breakpoints.XLarge
			? this.maxShown = 2
			: breakpoint < Breakpoints.XXLarge
			? this.maxShown = 3 : this.maxShown = 4
		},

		onSponsorClick: function(index) {
			const { sourceUrl } = this.sponsors[index]
			if (sourceUrl) window.open(sourceUrl, '_blank')
		}
	}
}