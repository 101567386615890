// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'LatestBulletin',
	mixins: [MixinResponsive],

	data: function() {
		return {
			responsive: {
				classMobileSmallBreakpoint: true,
				showMobileVersion: false
			},
			states: {
				description: '',
				photoUrl: '',
				sourceUrl: ''
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/Home/LatestBulletin.json')
		const data = await request.json()
		this.states = { description: data.description, photoUrl: data.photoUrl, sourceUrl: data.sourceUrl }
	},

	computed: {
		_getSmallBreakpointClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.classMobileSmallBreakpoint }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.classMobileSmallBreakpoint = breakpoint <= Breakpoints.Small
			responsive.showMobileVersion = breakpoint < Breakpoints.Large
		},

		onGoToLatestBulletin: function() {
			const { sourceUrl } = this.states
			if (sourceUrl) {
				window.open(sourceUrl, '_blank')
			}
		}
	}
}