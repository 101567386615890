// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'ItemsOnGrid',
	mixins: [MixinResponsive],

	data: function() {
		return {
			responsive: {
				showMobileVersion: false
			},
			states: {
				items: []
			}
		}
	},

	computed: {
		_getResponsiveBreakPoints: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XXLarge
		},

		onIOGButtonClick: function(item) {
			const { sourceUrl, sourceType } = item
			if (sourceUrl && sourceType) {
				switch (sourceType) {
					case 'external': return window.open(sourceUrl, '_blank')
					case 'internal':
						const routeData = this.$router.resolve({ name: sourceUrl })
						window.open(`${ routeData.href }?id=${ this.$route.query.id }`, '_blank')
						break
				}
			}
		}
	}
}