import { render, staticRenderFns } from "./template.vue?vue&type=template&id=897a8ad2&scoped=true&"
import script from "@/components/views/SocialMedia/component.js?vue&type=script&lang=js&"
export * from "@/components/views/SocialMedia/component.js?vue&type=script&lang=js&"
import style0 from "@/components/views/SocialMedia/style.scss?vue&type=style&index=0&id=897a8ad2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "897a8ad2",
  null
  
)

export default component.exports