// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'CourseSelectionForm',
	mixins: [MixinResponsive],

	data: function() {
		return {
			form: {
				selection: [],
				type: 'Cursos'
			},
			responsive: {
				showMobileVersion: false
			},
			states: {
				courses: [],
				graduates: []
			}
		}
	},

	created: async function() {
		const courses = await fetch('/resources/json/CourseEntryForm/Courses.json')
		const coursesData = await courses.json()
		const graduates = await fetch('/resources/json/CourseEntryForm/Diplomas.json')
		const graduatesData = await graduates.json()
		this.states = { courses: coursesData, graduates: graduatesData }
	},

	computed: {
		_getResponsiveClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_getEmptyText: function() {
			switch (this.form.type) {
				case 'Cursos': return 'Sin Cursos disponibles'
				case 'Diplomados': return 'Sin Diplomados disponibles'
				case 'Ambos': return 'Sin Cursos o Diplomados disponibles'
			}
		},

		_getItems: function() {
			switch (this.form.type) {
				case 'Cursos': return this.states.courses
				case 'Diplomados': return this.states.graduates
				case 'Ambos': return [...this.states.courses, ...this.states.graduates]
			}
			return []
		},

		_isEmpty: function() {
			return this._getItems().length === 0
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XLarge
		}
	}
}