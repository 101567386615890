// Mixin Export
export default {
	data: function() {
		return {
			__Toast:  {
				append: false,
				autoHideDelay: 4000,
				toaster: 'b-toaster-top-right',
				variant: null
			}
		}
	},

	methods: {
		showToast: function(title, message, variant = null) {
			const { __Toast } = this.$data
			this.$bvToast.toast(message, {
				title, variant,
				autoHideDelay: __Toast.autoHideDelay,
				appendToast: __Toast.append,
				toaster: __Toast.toaster
			})
		}
	}
}