// Components
import MainLogo from '@/components/common/MainLogo/template.vue'

// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

export default {
	name: 'Header',
	mixins: [MixinResponsive],

	components: {
		MainLogo
	},

	props: {
		background: String,
		iconSubtitle: [String, Array],
		showBanner: { type: Boolean, default: true },
		showButton: Boolean,
		showIconSubtitle: Boolean,
		showSubtitleOnMobile: Boolean,
		subtitle: String,
		title: String
	},

	data: function() {
		return {
			links: [
				{ label: 'Diplomados\ny Cursos', to: 'CoursesAndDiplomas' },
				{ label: 'Institucional', hasChildren: true, showChildren: false },
				{ label: 'Boletín IDEGYS', to: 'https://www.idegys.cl/boletin.html', external: true },
				{ label: 'Estudios', to: 'https://www.idegys.cl/estudios.html', external: true },
				{ label: 'Blog', to: 'https://www.idegys.cl/blogidegys/blog.html', external: true }
			],
			socialMedias: [
				{ icon: ['fab', 'facebook-f'], to: 'https://www.facebook.com/idegys' },
				{ icon: ['fab', 'twitter'], to: 'https://twitter.com/idegyscl' },
				{ icon: ['fab', 'linkedin'], to: 'https://www.linkedin.com/company/idegys' },
				{ icon: ['fab', 'instagram'], to: 'https://www.instagram.com/idegys/?hl=es-la' }
			],
			responsive: {
				classTopBarLink: true,
				scaleRightLogo: 5,
				scaleTopBarLogo: 1,
				showDesktopBanner: true,
				showLogoText: true,
				showMobileTopMenu: false,
				showTopBarLinks: true
			},
			states: {
				courseBackground: undefined,
				eventAttached: false,
				showPopupList: false
			}
		}
	},
	
	created: async function() {
		const courseDetails = await this._getCourseDetails()
		const courseId = this.$route.query.id

		// Background.
		if (!this.$props.background && courseId) {
			this.states.courseBackground = courseDetails[courseId].image
		}
	},

	computed: {
		_getResponsiveBreakPoints: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.classTopBarLink }
		}
	},

	methods: {
		_getCourse: async function() {
			const request = await fetch('/resources/json/CoursesAndDiplomas/Courses.json')
			const courses = await request.json()
			return courses.find((x) => x.id === this.$route.query.id)
		},

		_getCourseDetails: async function() {
			const request = await fetch('/resources/json/CourseDetails/Courses.json')
			return await request.json()
		},

		_getMobileMenuLinkChildren: function() {
			return [
				{ label: 'IDEGYS', to: 'https://www.idegys.cl/idegys.html' },
				{ label: 'Área de investigación y docencia', to: 'https://www.idegys.cl/docencia_investigacion.html' },
				{ label: 'Consejo y Gobernanza', to: 'https://www.idegys.cl/consejo_gobernanza.html' }
			]
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showDesktopBanner = breakpoint > Breakpoints.Large
			responsive.showLogoText = breakpoint > Breakpoints.XLarge
			responsive.showTopBarLinks = breakpoint > Breakpoints.Large

			// Remover el Evento si se oculto la Barra de Enlaces.
			if (!responsive.showTopBarLinks) this.onDetachDocumentMouseClick()

			// Controlar la Escala de los elementos según el Breakpoint.
			breakpoint < Breakpoints.XXLarge
			? responsive.scaleTopBarLogo = 0.8
			: responsive.scaleTopBarLogo = 1

			breakpoint > Breakpoints.XXLarge
			? responsive.scaleRightLogo = 5
			: breakpoint > Breakpoints.XLarge
			? responsive.scaleRightLogo = 4
			: responsive.scaleRightLogo = 3
		},

		_resolveHeaderBackground: function() {
			const { background } = this.$props
			const { courseBackground } = this.states
			
			// El fondo puede venir de 'assets' o 'resources'.
			let image = ''
			if (background) image = require(`@/assets/img/${ background }`)
			if (courseBackground) image = `/resources/images/Courses/${ courseBackground }`

			// Retornar el string con el estilo para una imagen de fondo.
			if (image) return `
				background-blend-mode: soft-light;
				background-color: rgba(59, 162, 193, 0.7);
				background-image: url(${ image });
				background-repeat: no-repeat;
				background-size: cover;
			`
			
			// Por defecto, un color solido.
			return 'background-color: #42a3c3;'
		},

		_startRedirect: function(item) {
			if (item.external) {
				window.open(item.to, '_blank')
			}
			else {
				const routeData = this.$router.resolve({ name: item.to })
				window.open(routeData.href, '_blank')
			}
		},

		onAttachDocumentMouseClick: function() {
			if (!this.states.eventAttached) {
				this.states.eventAttached = true
				document.addEventListener("click", this.onDocumentMouseClick)
			}
		},

		onDetachDocumentMouseClick: function() {
			const { eventAttached, showPopupList } = this.states
			if (eventAttached && showPopupList) {
				this.states.showPopupList = false
				this.states.eventAttached = false
				document.removeEventListener("click", this.onDocumentMouseClick)
			}
		},

		onDocumentMouseClick: function(event) {
			for (let $p of event.path) {
				if ($p.id === 'links') {
					return
				}
			}
			this.onDetachDocumentMouseClick()
		},

		onHButtonClick: async function(key) {
			if (key === 'postulate') {
				this.$emit('onHButtonClick')
				return
			}

			// Los otros dos botones, redireccionan segun el curso.
			let to = ''
			const course = await this._getCourse()

			// Redrección segun cada botón.
			if (key === 'program') to = course.buttonActions.PROGRAMA
			if (key === 'contents') to = course.buttonActions.CONTENIDOS
			this._startRedirect({ external: true, to })
		},

		onLinkClick: function(item) {
			if (item.hasChildren) {
				this.states.showPopupList = !this.states.showPopupList
			}
			else {
				this._startRedirect(item)
			}

			if (this.states.showPopupList === true && this.states.eventAttached === false) {
				this.onAttachDocumentMouseClick()
			}
			else {
				this.onDetachDocumentMouseClick()
			}
		},

		onMobileMenuChildClick: function(child) {
			window.open(child.to, '_blank')
		},

		onMobileMenuLinkClick: function(item) {
			if (item.hasChildren) {
				item.showChildren = !item.showChildren
				return
			}
			this._startRedirect(item)
		}
	}
}