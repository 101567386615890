// Components
import Footer from '@/components/common/Footer/template.vue'
import Header from '@/components/common/Header/template.vue'
import CoursesBox from '@/components/views/CoursesBox/template.vue'
import SocialMedia from '@/components/views/SocialMedia/template.vue'
import SponsorsSlider from '@/components/views/SponsorsSlider/template.vue'

// Component Export
export default {
	name: 'CoursesAndDiplomas',

	components: {
		CoursesBox,
		Header,
		Footer,
		SocialMedia,
		SponsorsSlider
	}
}