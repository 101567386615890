// Components
import Footer from '@/components/common/Footer/template.vue'
import Header from '@/components/common/Header/template.vue'
import ComplementaryRecords from '@/components/views/ComplementaryRecords/template.vue'
import ContactCard from '@/components/views/ContactCard/template.vue'
import CourseSelectionForm from '@/components/views/CourseSelectionForm/template.vue'
import GeneralForm from '@/components/views/GeneralForm/template.vue'
import LaborDataForm from '@/components/views/LaborDataForm/template.vue'
import PaymentMethods from '@/components/views/PaymentMethods/template.vue'
import SocialMedia from '@/components/views/SocialMedia/template.vue'

// Mixins
import MixinToast from '@/mixins/MixinToast'

// Component Export
export default {
	name: 'CourseEntryForm',
	mixins: [MixinToast],

	components: {
		ComplementaryRecords,
		ContactCard,
		CourseSelectionForm,
		GeneralForm,
		Footer,
		Header,
		LaborDataForm,
		PaymentMethods,
		SocialMedia
	},

	data: function() {
		return {
			emptyText: '<< No Especificado >>'
		}
	},

	methods: {
		_addComplementaryRecordsData: function(form) {
			const { complementaryRecords } = this.$refs
			const sourcesFromWhereGotInformation = JSON.stringify(complementaryRecords.getGroupOptions(0))
			const contactMethods = JSON.stringify(complementaryRecords.getGroupOptions(1))
			form.append('MediosDondeObtuvoInformacion', sourcesFromWhereGotInformation.replace(/\"/g, '').replace(/\,/g, ', '))
			form.append('MediosParaSerContactado',      contactMethods.replace(/\"/g, '').replace(/\,/g, ', '))
			form.append('DeseaRecibirNewsletter',       complementaryRecords.states.subscribe ? 'Si' : 'No')
		},

		_addCourseSelectionFormData: function(form) {
			const { courseSelectionForm } = this.$refs
			const data = JSON.stringify(courseSelectionForm.form.selection)
			form.append('CursosDiplomados', data.replace(/\"/g, '').replace(/\,/g, ', '))
		},

		_addGeneralFormData: function(form) {
			const { generalForm } = this.$refs
			form.append('Email',           generalForm.form.email          || this.emptyText)
			form.append('Nombres',         generalForm.form.name           || this.emptyText)
			form.append('ApellidoPaterno', generalForm.form.pLastName      || this.emptyText)
			form.append('ApellidoMaterno', generalForm.form.mLastName      || this.emptyText)
			form.append('Genero',          generalForm.form.gender         || this.emptyText)
			form.append('FechaNacimiento', generalForm.form.birthdate      || this.emptyText)
			form.append('TipoDocumento',   generalForm.form.documentType   || this.emptyText)
			form.append('NumDocumento',    generalForm.form.documentNumber || this.emptyText)
			form.append('Nacionalidad',    generalForm.form.nationality    || this.emptyText)
			form.append('NumDocumento',    generalForm.form.documentNumber || this.emptyText)
			form.append('NumContacto',     generalForm.form.phone          || this.emptyText)
			form.append('PaisResidencia',  generalForm.form.homeCountry    || this.emptyText)
			form.append('Region',          generalForm.form.region         || this.emptyText)
			form.append('Comuna',          generalForm.form.commune        || this.emptyText)
			form.append('Ciudad',          generalForm.form.city           || this.emptyText)
			form.append('Direccion',       generalForm.form.address        || this.emptyText)
		},

		_addLaborDataFormData: function(form) {
			const { laborDataForm } = this.$refs
			const postStudies = JSON.stringify(laborDataForm.form.postStudies)
			form.append('CentroEstudioPregado',       laborDataForm.form.preStudyCenter         || this.emptyText)
			form.append('PaisCentroEstudioPregrado',  laborDataForm.form.preStudyCenterCountry  || this.emptyText)
			form.append('CarreraEstudioPregrado',     laborDataForm.form.preStudyCenterCareer   || this.emptyText)
			form.append('EstudiosDePostgrado',        postStudies.replace(/\"/g, '').replace(/\,/g, ', '))
			form.append('CentroEstudiosPostgrado',    laborDataForm.form.postStudyCenter        || this.emptyText)
			form.append('PaisCentroEstudioPostgrado', laborDataForm.form.postStudyCenterCountry || this.emptyText)
			form.append('LugarDeTrabajo',             laborDataForm.form.workplace              || this.emptyText)
			form.append('Cargo',                      laborDataForm.form.position               || this.emptyText)
			form.append('PaisLugarDeTrabajo',         laborDataForm.form.workplaceCountry       || this.emptyText)
			form.append('Modalidad',                  laborDataForm.form.workplaceModality      || this.emptyText)
		},

		onSendFormDataToGoogleSheet: async function() {
			try {
				// Recopilar los datos desde los distintos componentes.
				const form = new FormData()

				// Fecha cuando fue enviada la información.
				const date = new Date()
				const userTimezoneOffset = date.getTimezoneOffset() * 60000
				const _date = new Date(date.getTime() - userTimezoneOffset).toISOString().split('T')
				form.append('FechaRegistro', `${ _date[0] } ${ _date[1].replace('Z', '').padStart(8, '0') }`)
				
				// Información presente en el componente 'GeneralForm'.
				this._addGeneralFormData(form)

				// Información presente en el componente 'CourseSelectionForm'.
				this._addCourseSelectionFormData(form)
				
				// Información presente en el componente 'LaborDataForm'.
				this._addLaborDataFormData(form)

				// Información presente en el componente 'ComplementaryRecords'.
				this._addComplementaryRecordsData(form)
				
				// Realizar la petición.
				this.showToast('Enviando su información', 'La información esta siendo enviada. Espere un momento...')
				const url = 'https://script.google.com/macros/s/AKfycbye5lsokt8oSwbUvrg-kQn9jBeFQTIa3F2yOPNJJoV483Q3anhWkJmbFGITrEINumlOUg/exec'

				const response = await fetch(url, { method: 'POST', body: form })

				// Si se obtuvo una respuesta satisfactoria, vaciar los formularios.
				if (response) {
					const { complementaryRecords, generalForm, laborDataForm } = this.$refs
					complementaryRecords.clear()
					generalForm.clear()
					laborDataForm.clear()
					this.showToast('Enviado correctamente', 'Los datos ingresados en el formulario fueron enviados correctamente!')
				}
			}
			catch (error) {
				console.error('Error:', error)
			}
		}
	}
}