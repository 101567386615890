// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'CoursesBox',
	mixins: [MixinResponsive],

	data: function() {
		return {
			responsive: {
				showMobileVersion: false
			},
			states: {
				courses: []
			}
		}
	},

	created: async function() {
		const request = await fetch('/resources/json/CoursesAndDiplomas/Courses.json')
		const data = await request.json()
		this.states = { courses: data || [] }
	},

	computed: {
		_getResponsiveBreakPoints: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_loadItemBackground: function(item) {
			return `
				background-image: url(${item.photoUrl});
				background-repeat: no-repeat;
				background-size: cover;
			`
		},

		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XXLarge
		},

		onButtonClick: function(item) {
			const routeData = this.$router.resolve({ name: item.redirectTo })
			window.open(`${ routeData.href }?id=${ item.id }`, '_blank')
		}
	}
}