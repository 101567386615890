// Constants
import { Breakpoints } from '@/constants/Breakpoints'

// Mixins
import MixinResponsive from '@/mixins/MixinResponsive'

// Component Export
export default {
	name: 'GeneralForm',
	mixins: [MixinResponsive],

	data: function() {
		return {
			form: {
				address: '',
				city: '',
				commune: '',
				birthdate: '',
				nationality: '',
				documentNumber: '',
				documentType: null,
				homeCountry: 'Chile',
				email: '',
				gender: null,
				name: '',
				mLastName: '',
				phone: '',
				pLastName: '',
				region: ''
			},
			responsive: {
				showMobileVersion: false
			},
			states: {
				regions: []
			}
		}
	},
	
	created: function() {
		this.states.regions = require('@/assets/json/regions.json')
	},

	computed: {
		_getCommunes: function() {
			const { region } = this.form
			const _region = this.states.regions.find((x) => x.name === region)
			return _region?.communes.sort() || []
		},

		_getRegions: function() {
			return this.states.regions.map((x) => {
				return x.name
			}).sort()
		},

		_getResponsiveClass: function() {
			return { [`${this.getBreakpointPrefix()}`]: this.responsive.showMobileVersion }
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint) {
			const { responsive } = this

			// Controlar la Visibilidad de los elementos según el Breakpoint.
			responsive.showMobileVersion = breakpoint < Breakpoints.XLarge
		},

		clear: function() {
			this.form = {
				address: '',
				city: '',
				commune: '',
				birthdate: '',
				nationality: '',
				documentNumber: '',
				documentType: null,
				homeCountry: 'Chile',
				email: '',
				gender: null,
				name: '',
				mLastName: '',
				phone: '',
				pLastName: '',
				region: ''
			}
		}
	}
}